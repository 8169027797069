import store from '../store';

export default {
    parseStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
    },
    getStatusColor(s){
        let color = 'grey'
        if(s===-1) color = 'warning'
        if(s===0) color = 'info'
        if(s===1) color = 'success'
        return color
    },
    getHPStatusColor(s){
        let color = 'grey'
        if(s===-1) color = 'warning'
        if(s===0) color = 'info'
        if(s===1) color = 'success'
        if(s===2) color = 'lime'
        if(s===3) color = 'error'
        return color
    },
    parseHPStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Active"
        else if(id===3) return "Closed"
        else if(id===4) return "Reposessed"
    },
    getStocktakingEventStatusColor(s){
        let color = 'grey'
        if(s===-1) color = 'warning'
        if(s===0) color = 'info'
        if(s===1) color = 'success'
        return color
    },
    parseStocktakingEventStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "New"
        else if(id===1) return "In-progress"
        else if(id===2) return "Complete"
    },
    parseCNStatus(id){
        if(id===-1) return "Voided"
        else if(id===1) return "Unredeemed"
        else if(id===2) return "Redeemed"
    },
    parsePOStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Fulfilled"
    },
    getPOStatusColor(s){
        let color = 'grey'
        if(s===-1) color = 'warning'
        if(s===0) color = 'info'
        if(s===1) color = 'success'
        return color
    },
    parseRRStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
    },
    getRRStatusColor(s){
        let color = 'grey'
        if(s===-1) color = 'warning'
        if(s===0) color = 'info'
        if(s===1) color = 'success'
        return color
    },
    parseITStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sent Out"
        else if(id===2) return "Received"
    },
    getITStatusColor(s){
        let color = 'grey'
        if(s===-1) color = 'warning'
        if(s===0) color = 'info'
        if(s===1) color = 'secondary'
        if(s===2) color = 'success'
        return color
    },
    parseDispatchStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "New"
        else if(id===1) return "In Progress"
        else if(id===2) return "Completed"
        else return "New"
    },
    getDispatchStatusColor(s){
        let color = 'info'
        if(s===-1) color = 'warning'
        if(s===0) color = 'info'
        if(s===1) color = 'warning'
        if(s===2) color = 'success'
        return color
    },
    formatDate(d, type="short"){
        if(!d) return "None";
        if(d==0) return "None";
        if(type=="short")
            return (new Date(d)).toLocaleDateString('en-TT')
        if(type=="long")
            return (new Date(d)).toLocaleDateString('en-TT', {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })
        if(type=="withTime")
            return (new Date(d)).toLocaleDateString('en-TT') + ' ' + this.formatTime(d)
    },
    formatTime(t){
        if(!t) return "None";
        return (new Date(t).toLocaleTimeString('en-TT'))
    },
    formatCurrency(value){
        if(value===null || value===undefined) return "";
        let formatter = new Intl.NumberFormat('en-TT', {
          style: 'currency',
          currency: 'TTD'
        });
        return formatter.format(parseFloat(value));
    },
    parseDate(date, type){
        if(!date){
            return ""
        }
        switch (type) {
            default:
                return new Date(date).toISOString().substring(0, 10);
            case 1:
                return new Date(date).toISOString().replace(/-/g, '/').replace(/T.+/, '')
            case 2:
                return new Date(new Date(date).toISOString().replace(/-/g, '/').replace(/T.+/, '')).toDateString()
            case 3:
                return new Date(new Date(date)).toLocaleString();
        }
    },
    parseOrderStatus(id){
        if(id===-1) return "Voided"
        else if(id===0) return "Draft"
        else if(id===1) return "Sealed"
        else if(id===2) return "Admin Sealed"
        else if(id===3) return "Delivery Scheduled"
        else if(id===4) return "Pending Reschedule"
        else if(id===5) return "Out For Delivery"
        else if(id===6) return "Delivered"
    },
    openInNewTab(router, link, external=false){
        let route;
        if(!external) route = router.resolve({ path: link });
        window.open(external?link:route.href, "_blank")
    },
    pff2(val){
        return parseFloat(parseFloat(val).toFixed(2))
    },
    daysBetween(first,second) {
        return Math.round((second - first) / (1000 * 60 * 60 * 24))
    },
    pff(val, n){
        if(!n){
            let v = store.getters.getGlobalValue("quantityPrecision");
            n = parseInt(v || "0")
        }

        return parseFloat(parseFloat(val).toFixed(n))
    },
    getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return null;
    }
}